import axios from '@axios'
import {parse, stringify} from 'flatted';


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('http://127.0.0.1:8000/api/devis', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`http://127.0.0.1:8000/api/devis/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/invoice/clients')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDevis(ctx, state) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://127.0.0.1:8000/api/devis', { devis: state })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    addDevis2(ctx, state) {
      return new Promise((resolve, reject) => {
        let states = "mehdi" 
        let test = stringify(state.display)
        console.log('la')
        axios
          .post('http://127.0.0.1:8000/api/devis', { devis: test })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
